import router from '@/app/app-routes';
import store from '@/app/app-state';
import { computed, ref } from '@vue/composition-api';
import { Notification } from 'element-ui';
import Vue from 'vue';
import { CLIENT_TYPE_STORE_MODULE_NAME } from './clientTypesStoreModule';

const routeData = Vue.observable({ params: {}, query: {} });

router.afterEach((route) => {
  routeData.params = route.params;
  routeData.query = route.query;
});

export function useClientTypeList() {
  const createClientTypeRef = ref(null);
  const updateClientTypeRef = ref(null);

  const meta = computed(() => store.state[CLIENT_TYPE_STORE_MODULE_NAME].meta);

  const data = computed(() => store.state[CLIENT_TYPE_STORE_MODULE_NAME].data);

  const fetchClientTypes = (params) => {
    store.dispatch(`${CLIENT_TYPE_STORE_MODULE_NAME}/fetchClientTypes`, params);
  };

  const changePage = (page) => {
    router.replace({ query: { ...routeData.query, page } });
    fetchClientTypes({
      page,
    });
  };

  const createClientType = async () => {
    const createResult = await createClientTypeRef.value.open();
    if (createResult) {
      Notification.success({
        title: 'Успех',
        message: 'Тип клиента успешно добавлен',
      });
    }
  };

  const updateClientType = async (d) => {
    const updateResult = await updateClientTypeRef.value.open(d);
    if (updateResult) {
      Notification.success({
        title: 'Успех',
        message: 'Тип клиента успешно обновлен',
      });
    }
  };

  const deleteClientType = ({ id }) => {
    try {
      store.dispatch(`${CLIENT_TYPE_STORE_MODULE_NAME}/deleteClientTypes`, { id });
      Notification.success({
        title: 'Успех',
        message: 'Тип клиента успешно удален.',
      });
    } catch (err) {
      Notification.error({
        title: 'Ошибка',
        message: 'Ошибка удаления.',
      });
    }
  };

  fetchClientTypes({ page: routeData.query.page || 1 });

  return {
    data,
    meta,
    changePage,
    createClientTypeRef,
    createClientType,
    updateClientTypeRef,
    updateClientType,
    deleteClientType,
  };
}
