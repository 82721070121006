<template>
  <el-dialog title="Обновление типа клиента" :visible.sync="isOpen" width="30%">
    <el-form
      v-if="clientType"
      ref="formUpdateRef"
      :model="clientType"
      :rules="rules"
      label-width="100px"
      label-position="top"
    >
      <el-form-item label="Название" prop="title">
        <el-input v-model="clientType.title" />
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="isOpen = false">Закрыть</el-button>
      <el-button type="primary" @click="saveClientType">Сохранить</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { useClientTypeUpdate } from './useClientTypeUpdate';

export default {
  name: 'ClientTypeUpdate',
  setup() {
    const { isOpen, open, clientType, saveClientType, rules, formUpdateRef } =
      useClientTypeUpdate();

    return {
      isOpen,
      open,
      clientType,
      saveClientType,
      rules,
      formUpdateRef,
    };
  },
};
</script>
