import store from '@/app/app-state';
import { ref } from '@vue/composition-api';
import { CLIENT_TYPE_STORE_MODULE_NAME } from '../clientTypesStoreModule';

export function useClientTypeCreate() {
  const isOpen = ref(false);
  const clientType = ref({});
  const formСreateRef = ref(null);

  const rules = {
    title: [{ required: true, message: 'Укажите название типа', trigger: 'change' }],
  };

  let createDialogController = null;

  const open = () => {
    let resolve;
    let reject;
    const createDialogPromise = new Promise((ok, fail) => {
      resolve = ok;
      reject = fail;
    });
    createDialogController = { resolve, reject };
    isOpen.value = true;
    return createDialogPromise;
  };

  const saveClientType = () => {
    try {
      const { title } = clientType.value;
      formСreateRef.value.validate((valid) => {
        if (valid) {
          store.dispatch(`${CLIENT_TYPE_STORE_MODULE_NAME}/createClientTypes`, { title });
          createDialogController.resolve(true);
          isOpen.value = false;
          clientType.value = {};
          return;
        }
        return false;
      });
    } catch (e) {
      createDialogController.reject(false);
    }
  };

  return {
    isOpen,
    open,
    clientType,
    saveClientType,
    rules,
    formСreateRef,
  };
}
