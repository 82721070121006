<template>
  <div class="client-type-list">
    <div class="is-flex is-justify-content-flex-end">
      <el-button type="primary" icon="el-icon-plus" @click="createClientType">
        Добавить тип клиента
      </el-button>
    </div>
    <el-table :data="data" style="width: 100%" lazy>
      <el-table-column prop="id" label="#" width="80" />
      <el-table-column prop="title" label="Название" />
      <el-table-column width="120">
        <template slot-scope="{ row }">
          <el-button
            size="mini"
            type="primary"
            icon="el-icon-edit"
            @click="updateClientType(row)"
          />
          <el-button
            size="mini"
            type="danger"
            icon="el-icon-delete"
            @click="deleteClientType(row)"
          />
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination is-flex is-justify-content-center mt-5">
      <el-pagination
        background
        layout="prev, pager, next"
        :current-page="meta.current_page"
        :page-size="meta.per_page"
        :page-count="meta.last_page"
        @current-change="changePage"
      />
    </div>
    <client-type-create ref="createClientTypeRef" />
    <client-type-update ref="updateClientTypeRef" />
  </div>
</template>
<script>
import store from '@/app/app-state';
import { onUnmounted } from '@vue/composition-api';
import { CLIENT_TYPE_STORE_MODULE_NAME, clientTypesStoreModule } from './clientTypesStoreModule';
import { useClientTypeList } from './useClientTypeList';
import ClientTypeCreate from './client-type-create/client-type-create.vue';
import ClientTypeUpdate from './client-type-update/client-type-update.vue';

export default {
  components: { ClientTypeUpdate, ClientTypeCreate },
  setup() {
    if (!store.hasModule(CLIENT_TYPE_STORE_MODULE_NAME)) {
      store.registerModule(CLIENT_TYPE_STORE_MODULE_NAME, clientTypesStoreModule);
    }
    onUnmounted(() => {
      if (store.hasModule(CLIENT_TYPE_STORE_MODULE_NAME)) {
        store.unregisterModule(CLIENT_TYPE_STORE_MODULE_NAME);
      }
    });

    const {
      data,
      meta,
      changePage,
      createClientTypeRef,
      createClientType,
      updateClientTypeRef,
      updateClientType,
      deleteClientType,
    } = useClientTypeList();

    return {
      data,
      meta,
      changePage,
      createClientTypeRef,
      createClientType,
      updateClientTypeRef,
      updateClientType,
      deleteClientType,
    };
  },
};
</script>
